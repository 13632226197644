module.exports = {
  groups: [
    'G1',
    'G2',
    'G3',
    'G4',
    'G5',
    'G6',
    // 'G7',
    'G8',
    'G9',
    'G10',
    'G11',
    'G12',
    'G13',
    'G14',
    'G15',
    'G16',
    'G17',
    'G18',
    'G19',
    'G20',
    'G21',
    'G22',
    'G23',
    'G24',
    // 'G25',
    'G26',
    'G27'
  ],
  staircases: ['T1', 'T2', 'T3'],
  ways: ['JAKOB', 'BERLINER', 'PASSAGE'],
  mapping: {
    Shop: 'EINKAUFEN & GENIESSEN',
    Office: 'BÜROS & DIENSTLEISTER',
  },
};
